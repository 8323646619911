const initialState = {
    posts: [],
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    switch(action.type) {
      // Add cases here
      default:
        return state;
    }
  }