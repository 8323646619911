import React from 'react';

function Gospel() {
  return (
    <div>
      <h2>복음 페이지</h2>
    </div>
  );
}

export default Gospel;