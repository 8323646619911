import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

const ProfileImageUpload = ({
    profile,
    defaultProfileImage,
    openImageModal,
}) => {
    const imageUrl = profile.user_img
        ? `${process.env.REACT_APP_PROFILE_IMG_URL}/${profile.user_img}`
        : defaultProfileImage;
    return (
        <ProfileImageContainer>
            <ProfileImage
                src={imageUrl}
                alt="Profile"
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultProfileImage;
                }}
            />
            <ImageUploadLabel onClick={openImageModal}>
                <FontAwesomeIcon icon={faCamera}  />
            </ImageUploadLabel>
        </ProfileImageContainer>
    );
};

const ProfileImageContainer = styled.div`
    position: relative;
`;

const ProfileImage = styled.img`
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid ${({ theme }) => theme.primaryColor};
`;

const ImageUploadLabel = styled.label`
    position: absolute;
    bottom: 0;

    background: ${({ theme }) => theme.primaryColor};
    color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
`;

export default ProfileImageUpload;
