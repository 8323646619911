import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ChevronDown, ChevronUp } from "react-feather";
import { FaCheck } from "react-icons/fa";

const AgreementContainer = styled.div`
  width: 95%;
  max-width: 500px;
  margin: 0 auto;

  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

const WelcomeMessage = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  color: ${({ theme }) => theme.text};
`;

const SubMessage = styled.p`
  text-align: center;
  margin-bottom: 30px;
  font-size: 13px;
  color: ${({ theme }) => theme.text};
  opacity: 0.8;
`;

const AgreementInlineContainer = styled.div`
  margin-bottom: 80px;
`;

const AgreementItem = styled.div`
  margin-bottom: 5px;
`;

const AgreementHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: ${({ theme }) => theme.body};
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.toggleBorder};
`;

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`;

const AgreementContent = styled.div`
  padding: 15px;
  background-color: ${({ theme }) => theme.body};
  font-size: 14px;
  line-height: 1.5;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
`;

const NextButton = styled.button`
  width: 85%;
  padding: 15px;
  background-color: ${(props) =>
    props.active ? props.theme.buttonBackground : props.theme.toggleBorder};
  color: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.buttonHoverBackground
        : props.theme.toggleBorder};
  }
`;

const agreements = [
  {
    title: "서비스 이용 약관",
    content:
      "서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...서비스 제공 및 이용에 관한 기본적인 규정...",
    required: true,
  },
  {
    title: "개인정보 처리방침",
    content: "개인정보 수집, 이용, 보관, 처리 방법에 대한 정책...",
    required: true,
  },
  {
    title: "위치 정보 서비스 이용 약관",
    content: "위치 기반 서비스를 이용할 경우 필요한 약관...",
    required: true,
  },
  {
    title: "마케팅 정보 수신 동의",
    content: "이메일, 문자 등을 통한 마케팅 정보 수신 동의...",
    required: false,
  },
  {
    title: "쿠키 사용 정책",
    content: "쿠키 수집 및 사용에 관한 정책...",
    required: true,
  },
];

const AgreementStep = ({ updateSignupData, signupData }) => {
  const [checkedItems, setCheckedItems] = useState(
    signupData.agreements || new Array(agreements.length).fill(false)
  );
  const [expandedItems, setExpandedItems] = useState(
    new Array(agreements.length).fill(false)
  );
  const [isAllRequiredChecked, setIsAllRequiredChecked] = useState(false);

  useEffect(() => {
    setIsAllRequiredChecked(
      agreements
        .filter((agreement) => agreement.required)
        .every((agreement, index) => {
          const requiredIndex = agreements.findIndex(
            (item) => item.title === agreement.title
          );
          return checkedItems[requiredIndex];
        })
    );
  }, [checkedItems]);

  const handleCheckboxChange = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };

  const handleExpandToggle = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  const handleNextClick = () => {
    if (isAllRequiredChecked) {
      updateSignupData({ agreements: checkedItems });
    }
  };

  return (
    <AgreementContainer>
      <WelcomeMessage>코람데오에 오신 것을 환영합니다! 🎉</WelcomeMessage>
      <SubMessage>회원가입을 위해 아래 약관을 읽고 동의해주세요. 📜</SubMessage>
      <AgreementInlineContainer>
        {agreements.map((agreement, index) => (
          <AgreementItem key={index}>
            <AgreementHeader>
              <CheckboxLabel onClick={() => handleCheckboxChange(index)}>
                {checkedItems[index] ? (
                  <FaCheck size={20} color="#4CAF50" />
                ) : (
                  <FaCheck size={20} color="#ccc" />
                )}
                {agreement.title} {agreement.required ? "(필수)" : "(선택)"}
              </CheckboxLabel>
              {expandedItems[index] ? (
                <ChevronUp onClick={() => handleExpandToggle(index)} />
              ) : (
                <ChevronDown onClick={() => handleExpandToggle(index)} />
              )}
            </AgreementHeader>
            {expandedItems[index] && (
              <AgreementContent>{agreement.content}</AgreementContent>
            )}
          </AgreementItem>
        ))}
      </AgreementInlineContainer>

      <NextButton active={isAllRequiredChecked} onClick={handleNextClick}>
        동의하고 시작하기! 🚀
      </NextButton>
    </AgreementContainer>
  );
};

export default AgreementStep;
