import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const NotificationsWrapper = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
`;

const NotificationItem = styled.div`
  background-color: ${({ theme, isRead }) => isRead ? theme.cardBackground : theme.highlightBackground};
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
`;

const NotificationContent = styled.div`
  flex-grow: 1;
`;

const NotificationMessage = styled.p`
  margin: 0;
`;

const NotificationDate = styled.span`
  font-size: 0.8em;
  color: ${({ theme }) => theme.secondaryText};
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-size: 1.2em;
`;

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const { isDarkMode } = useTheme();
  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notifications`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const markAsRead = useCallback((notificationId) => {
    setNotifications(prevNotifications =>
      prevNotifications.map(notification =>
        notification.id === notificationId
          ? { ...notification, is_read: true }
          : notification
      )
    );
    // 여기서 서버에 읽음 상태를 업데이트하는 요청을 보낼 수 있습니다.
    // 하지만 즉시 보내지 않고 나중에 일괄 처리할 수도 있습니다.
  }, []);

  const removeNotification = useCallback((notificationId) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notification => notification.id !== notificationId)
    );
    // 여기서 서버에 삭제 요청을 보낼 수 있습니다.
  }, []);

  return (
    <NotificationsWrapper>
      <h1>알림</h1>
      {notifications.map((notification) => (
        <NotificationItem 
          key={notification.id} 
          isRead={notification.is_read}
          onClick={() => markAsRead(notification.id)}
        >
          <NotificationContent>
            <NotificationMessage>{notification.message}</NotificationMessage>
            <NotificationDate>{new Date(notification.created_at).toLocaleString()}</NotificationDate>
          </NotificationContent>
          <CloseButton onClick={(e) => {
            e.stopPropagation();
            removeNotification(notification.id);
          }}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
        </NotificationItem>
      ))}
      {notifications.length === 0 && <p>새로운 알림이 없습니다.</p>}
    </NotificationsWrapper>
  );
}

export default Notifications;