import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HeaderWrapper = styled.header`
    background-color: ${({ theme }) => theme.headerBackground};
    padding: 1rem;
    position: fixed;
    left: 0;
    bottom: 0;

    right: 0;
    z-index: 1000;
`;

const Nav = styled.nav`
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-around;
    }

    li {
        margin: 0 10px;
    }

    a {
        color: ${({ theme }) => theme.headerText};
        text-decoration: none;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
            color: ${({ theme }) => theme.headerHoverText};
        }
    }
`;

function Header() {
    return (
        <HeaderWrapper>
            <Nav>
                <ul>
                    <li>
                        <Link to="/">홈</Link>
                    </li>
                    <li>
                        <Link to="/communication">소통</Link>
                    </li>
                    <li>
                        <Link to="/gospel">복음</Link>
                    </li>
                    <li>
                        <Link to="/prayer">기도</Link>
                    </li>
                    <li>
                        <Link to="/gratitude">감사</Link>
                    </li>
                    <li>
                        <Link to="/advanced">심화</Link>
                    </li>
                </ul>
            </Nav>
        </HeaderWrapper>
    );
}

export default Header;
