import React, { createContext, useState, useContext, useEffect } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [primaryColor, setPrimaryColor] = useState("#62A24D");
    const [isSystemTheme, setIsSystemTheme] = useState(true);

    useEffect(() => {
        const savedTheme = localStorage.getItem("theme");
        if (savedTheme) {
            setIsDarkMode(savedTheme === "dark");
            setIsSystemTheme(false);
        } else {
            const darkModeMediaQuery = window.matchMedia(
                "(prefers-color-scheme: dark)"
            );
            setIsDarkMode(darkModeMediaQuery.matches);

            // 시스템 테마 변경 감지
            const handleChange = (e) => {
                if (isSystemTheme) {
                    setIsDarkMode(e.matches);
                }
            };
            darkModeMediaQuery.addListener(handleChange);
            return () => darkModeMediaQuery.removeListener(handleChange);
        }

        const savedPrimaryColor = localStorage.getItem("primaryColor");
        if (savedPrimaryColor) {
            setPrimaryColor(savedPrimaryColor);
        }
    }, [isSystemTheme]);

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem("theme", newMode ? "dark" : "light");
            setIsSystemTheme(false);
            return newMode;
        });
    };

    const updatePrimaryColor = (color) => {
        setPrimaryColor(color);
        localStorage.setItem("primaryColor", color);
    };

    const setSystemTheme = (isDark) => {
        setIsDarkMode(isDark);
        localStorage.removeItem("theme");
        setIsSystemTheme(true);
    };

    return (
        <ThemeContext.Provider
            value={{
                isDarkMode,
                toggleTheme,
                primaryColor,
                updatePrimaryColor,
                setSystemTheme,
                isSystemTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
