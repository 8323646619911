import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    faEnvelope,
    faEdit,
    faTimes,
    faCog,
} from "@fortawesome/free-solid-svg-icons";
import BottomSheet from "../../components/BottomSheet";
import ThemeSelectionOption from "./ThemeSelectionOpton";
import ProfileInfo from "./ProfileInfo";
import ProfileImageUpload from "./ProfileImageUpload";
import ProfileImageModal from "./ProfileImageModal"; // Import the new component
import defaultProfileImage from "../../assets/originalProfileImage.jpg";

const ProfileContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
`;

const ProfileCard = styled.div`
    width: 100%;
    max-width: 500px;
    background: ${({ theme }) => theme.formBackground};
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    position: relative;
`;
const Button = styled.button`
    background: none;
    color: ${({ theme }) => theme.buttonText};
    border: 2px solid ${({ theme }) => theme.buttonBackground};
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.buttonBackground};
        color: ${({ theme }) => theme.buttonHoverText};
    }
`;
const EditButton = styled(Button)`
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: ${({ theme }) => theme.primaryColor};
    cursor: pointer;
    font-size: 18px;

    &:hover {
        color: ${({ theme }) => theme.primaryColorHover};
    }
`;

const SettingsButton = styled(Button)`
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    color: ${({ theme }) => theme.primaryColor};
    cursor: pointer;
    font-size: 18px;

    &:hover {
        color: ${({ theme }) => theme.primaryColorHover};
    }
`;

const Username = styled.h2`
    margin-bottom: 5px;
    color: ${({ theme }) => theme.primaryColor};
`;

const LoadingMessage = styled.div`
    margin-top: 20px;
    color: ${({ theme }) => theme.text};
`;

function Profile() {
    const [profile, setProfile] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({});
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [bottomSheetHeight, setBottomSheetHeight] = useState("70vh");
    const [isImageModalOpen, setIsImageModalOpen] = useState(false); // State for image modal
    const fetchProfile = async () => {
        try {
            const token =
                localStorage.getItem("token") ||
                sessionStorage.getItem("token");
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/auth/me`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const stats = await getUserStats(response.data.id);
            setProfile({ ...response.data, ...stats });
            setFormData({ ...response.data, ...stats });
        } catch (err) {
            toast.error("로그인이 필요합니다.");
            window.location.href = "/login";
            console.error(err);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const checkNickname = async () => {
        try {
            const token =
                localStorage.getItem("token") ||
                sessionStorage.getItem("token");

            const nicknameCheckResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/user/check-nickname`,
                { nickname: formData.nickname, userId: profile.id },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!nicknameCheckResponse.data.available) {
                toast.error(nicknameCheckResponse.data.error);
            } else {
                toast.success("사용 가능한 닉네임입니다.");
            }
        } catch (err) {
            toast.error("닉네임 확인 중 오류가 발생했습니다.");
            console.error(err);
        }
    };

    const handleSave = async () => {
        try {
            const token =
                localStorage.getItem("token") ||
                sessionStorage.getItem("token");

            const nicknameCheckResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/user/check-nickname`,
                { nickname: formData.nickname, userId: profile.id },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!nicknameCheckResponse.data.available) {
                toast.error(nicknameCheckResponse.data.error);
                return;
            }

            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/api/user/update`,
                {
                    ...formData,
                    birthdate: new Date(formData.birthdate)
                        .toISOString()
                        .split("T")[0],
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setProfile(response.data);
            setEditMode(false);
            toast.success("프로필이 성공적으로 업데이트되었습니다.");
        } catch (err) {
            toast.error("프로필 정보를 업데이트하는 중 오류가 발생했습니다.");
            console.error(err);
        }
    };

    const handlePasswordReset = async () => {
        try {
            const token =
                localStorage.getItem("token") ||
                sessionStorage.getItem("token");
            await axios.post(
                `${process.env.REACT_APP_API_URL}/api/user/password-reset`,
                { email: profile.email },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("비밀번호 변경 이메일이 발송되었습니다.");
        } catch (err) {
            toast.error(
                "비밀번호 변경 이메일을 발송하는 중 오류가 발생했습니다."
            );
            console.error(err);
        }
    };

    const handleCopyInviteCode = () => {
        const inviteText = `${process.env.REACT_APP_BASE_URL}/signup?referralCode=${profile.invite_code}`;
        navigator.clipboard.writeText(inviteText);
        toast.success("초대 링크가 복사되었습니다!");
    };

    const handleShareInviteCode = () => {
        const inviteText = `내 초대 링크: ${process.env.REACT_APP_BASE_URL}/signup?referralCode=${profile.invite_code} - 지금 가입하고 복음 전도 코드를 사용해 친구들을 초대하세요! 더 많은 경험치와 보상을 받을 수 있습니다.`;
        if (navigator.share) {
            navigator.share({
                title: "초대 링크",
                text: inviteText,
            });
            toast.success("초대 링크가 공유되었습니다!");
        } else {
            toast.error("공유 기능이 지원되지 않는 브라우저입니다.");
        }
    };

    const toggleBottomSheet = () => {
        setIsBottomSheetOpen(!isBottomSheetOpen);
    };

    const getUserStats = async (userId) => {
        try {
            const token =
                localStorage.getItem("token") ||
                sessionStorage.getItem("token");
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/user/stats/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error("Failed to fetch user stats", error);
            throw error;
        }
    };

    if (!profile) {
        return <LoadingMessage>로딩 중...</LoadingMessage>;
    }

    const options = [
        {
            title: "테마 변경",
            component: <ThemeSelectionOption />,
            icon: faCog,
            onClick: () => console.log("테마 변경 선택"),
            height: "50%",
        },
        {
            title: "문의 하기",
            component: <>문의하기 컴포넌트</>,
            icon: faEnvelope,
            onClick: () => console.log("문의 하기 선택"),
            height: "50%",
        },
    ];

    return (
        <ProfileContainer>
            <ProfileCard>
                <ProfileImageUpload
                    profile={profile}
                    setProfile={setProfile}
                    defaultProfileImage={defaultProfileImage}
                    openImageModal={() => setIsImageModalOpen(true)} // Open modal
                />
                <Username>{profile.username}</Username>
                <div>
                    게시글: {profile.posts} | 팔로워: {profile.followers} |
                    팔로잉: {profile.following}
                </div>
                <EditButton onClick={() => setEditMode(!editMode)}>
                    <FontAwesomeIcon icon={editMode ? faTimes : faEdit} />
                </EditButton>
                <SettingsButton onClick={toggleBottomSheet}>
                    <FontAwesomeIcon icon={faCog} />
                </SettingsButton>
                <ProfileInfo
                    formData={formData}
                    handleInputChange={handleInputChange}
                    checkNickname={checkNickname}
                    handleSave={handleSave}
                    handlePasswordReset={handlePasswordReset}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    handleCopyInviteCode={handleCopyInviteCode}
                    handleShareInviteCode={handleShareInviteCode}
                    profile={profile}
                />
            </ProfileCard>
            <BottomSheet
                isOpen={isBottomSheetOpen}
                onClose={toggleBottomSheet}
                title="설정"
                options={options}
                height={bottomSheetHeight}
                minHeight="20vh"
            />
            {options.map((option, index) => (
                <BottomSheet
                    key={index}
                    isOpen={selectedOption === option.title}
                    onClose={() => setSelectedOption("")}
                    title={option.title}
                    height={option.height}
                >
                    {option.component}
                </BottomSheet>
            ))}
            <ToastContainer />
            <ProfileImageModal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                profile={profile}
                setProfile={setProfile}
                defaultProfileImage={defaultProfileImage}
            />
        </ProfileContainer>
    );
}

export default Profile;
