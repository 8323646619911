import React, { useState, useEffect } from "react";
import styled from "styled-components";

const NameStepContainer = styled.div`
  width: 95%;
  max-width: 400px;
  margin: 0 auto;

  padding-bottom: 80px; // Ensure there's space at the bottom for the button
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.text};
  opacity: 0.8;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};

  &:focus {
    border-color: ${({ theme }) => theme.primaryColor};
    outline: none;
  }
`;

const SubmitButton = styled.button`
  width: 85%;
  padding: 15px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.toggleBorder : theme.buttonBackground};
  color: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.buttonHoverBackground};
  }
`;

const Message = styled.p`
  font-size: 14px;
  margin-top: 5px;
  color: ${(props) => (props.error ? "red" : "green")};
`;

const NameStep = ({ updateSignupData, signupData }) => {
  const [name, setName] = useState(signupData.name || "");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (signupData.name) {
      setName(signupData.name);
    }
  }, [signupData.name]);

  const validateName = (name) => {
    // 이름 유효성 검사: 2-7자
    return name.length >= 2 && name.length <= 7;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateName(name)) {
      setMessage("이름은 2-7자 사이여야 합니다. ✍️");
      setIsError(true);
      return;
    }

    updateSignupData({ name });
  };

  return (
    <NameStepContainer>
      <Title>뭐라고 불러드려야 할까요? 🤔</Title>
      <Description>🙏 성도님의 이름을 입력해주세요. 📝</Description>
      <Form onSubmit={handleSubmit}>
        <InputContainer>
          <Input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setMessage("");
              setIsError(false);
            }}
            placeholder="이름"
            required
          />
        </InputContainer>
        {message && <Message error={isError}>{message}</Message>}
        <SubmitButton type="submit" disabled={!name}>
          다음
        </SubmitButton>
      </Form>
    </NameStepContainer>
  );
};

export default NameStep;
