import React, { useState, useEffect } from "react";
import {
    Route,
    Routes,
    useNavigate,
    Navigate,
    useLocation,
} from "react-router-dom";
import styled from "styled-components";
import SignupHeader from "./SignupSteps/SignupHeader";
import AgreementStep from "./SignupSteps/AgreementStep";
import ThemeSelectionStep from "./SignupSteps/ThemeSelectionStep";
import EmailStep from "./SignupSteps/EmailStep";
import PasswordStep from "./SignupSteps/PasswordStep";
import NameStep from "./SignupSteps/NameStep";
import BirthdateStep from "./SignupSteps/BirthdateStep";
import NicknameStep from "./SignupSteps/NicknameStep";
import ReferralStep from "./SignupSteps/ReferralStep";
import CompleteStep from "./SignupSteps/CompleteStep";

const SignupContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const StepContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 60px;
`;

const steps = [
    { path: "agreement", component: AgreementStep, title: "약관 동의" },
    {
        path: "theme-selection",
        component: ThemeSelectionStep,
        title: "테마 선택",
    },
    { path: "name", component: NameStep, title: "이름 입력" },
    { path: "email", component: EmailStep, title: "이메일 입력" },
    { path: "password", component: PasswordStep, title: "비밀번호 설정" },
    { path: "birthdate", component: BirthdateStep, title: "생년월일 입력" },
    { path: "nick", component: NicknameStep, title: "닉네임 설정" },
    { path: "referral", component: ReferralStep, title: "추천인 코드" },
    { path: "completion", component: CompleteStep, title: "가입 완료" },
];

const Signup = () => {
    const [signupData, setSignupData] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const referralCode = params.get("referralCode");
        if (referralCode) {
            setSignupData((prev) => ({ ...prev, referralCode }));
        }
        // Ensure the first step is always agreement
        if (location.pathname === "/signup") {
            navigate(
                `/signup/agreement${
                    referralCode ? `?referralCode=${referralCode}` : ""
                }`
            );
        }
    }, [location, navigate]);

    const updateSignupData = (data) => {
        setSignupData((prev) => ({ ...prev, ...data }));
        const currentStepIndex = steps.findIndex((step) =>
            window.location.pathname.includes(step.path)
        );
        if (currentStepIndex < steps.length - 1) {
            const nextStepPath = `/signup/${steps[currentStepIndex + 1].path}`;
            const referralQuery = signupData.referralCode
                ? `?referralCode=${signupData.referralCode}`
                : "";
            navigate(`${nextStepPath}${referralQuery}`);
        }
    };

    const getCurrentStep = () => {
        const currentStepIndex = steps.findIndex((step) =>
            window.location.pathname.includes(step.path)
        );
        return currentStepIndex + 1;
    };

    return (
        <SignupContainer>
            <SignupHeader
                currentStep={getCurrentStep()}
                totalSteps={steps.length}
                title={steps[getCurrentStep() - 1]?.title || "회원가입"}
            />
            <StepContainer>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to="/signup/agreement" replace />}
                    />
                    {steps.map((step, index) => (
                        <Route
                            key={step.path}
                            path={step.path}
                            element={
                                <step.component
                                    updateSignupData={updateSignupData}
                                    signupData={signupData}
                                />
                            }
                        />
                    ))}
                </Routes>
            </StepContainer>
        </SignupContainer>
    );
};

export default Signup;
