import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faBirthdayCake,
    faUser,
    faStar,
    faCode,
    faMapMarkerAlt,
    faKey,
    faSave,
    faCopy,
    faShareAlt,
    faCog,
} from "@fortawesome/free-solid-svg-icons";

const ProfileInfoContainer = styled.div`
    text-align: left;
    margin-top: 20px;
`;

const ProfileInfoItem = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 16px;
    & > svg {
        margin-right: 10px;
        color: ${({ theme }) => theme.primaryColor};
    }
`;
const Button = styled.button`
    background: none;
    color: ${({ theme }) => theme.buttonText};
    border: 2px solid ${({ theme }) => theme.buttonBackground};
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.buttonBackground};
        color: ${({ theme }) => theme.buttonHoverText};
    }
`;
const Input = styled.input`
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.inputBorder};
    border-radius: 5px;
    width: 100%;
    background-color: ${({ theme }) => theme.inputBackground};
    color: ${({ theme }) => theme.inputText};

    &:focus {
        border-color: ${({ theme }) => theme.inputFocusBorder};
    }
    &.with-button {
        padding-right: 90px;
    }
    & > svg {
        margin-right: 10px;
        color: ${({ theme }) => theme.primaryColor};
    }
`;

const Select = styled.select`
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.inputBorder};
    border-radius: 5px;
    width: 100%;
    background-color: ${({ theme }) => theme.inputBackground};
    color: ${({ theme }) => theme.inputText};

    &:focus {
        border-color: ${({ theme }) => theme.inputFocusBorder};
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SaveButton = styled(Button)`
    border-color: ${({ theme }) => theme.primaryColor};

    &:hover {
        background-color: ${({ theme }) => theme.primaryColor};
    }
`;
const CancelButton = styled(Button)`
    border-color: red;

    &:hover {
        background-color: red;
    }
`;
const PasswordButton = styled(Button)`
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    width: 100%;
    border-color: ${({ theme }) => theme.primaryColor};

    &:hover {
        background-color: ${({ theme }) => theme.primaryColor};
    }
`;
const InviteCodeText = styled.p`
    font-size: 16px;
    color: ${({ theme }) => theme.text};
    margin-right: 10px;
`;

const InviteCodeButton = styled(Button)`
    background: none;
    border: 1px solid ${({ theme }) => theme.primaryColor};
    padding: 8px 12px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;

    & > svg {
        margin-right: 5px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.primaryColor};
        color: ${({ theme }) => theme.primaryColor};
    }
`;

const NicknameContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

const CheckButton = styled(Button)`
    display: flex;
    padding: 8px 12px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    font-size: 10px;
    transform: translateY(-50%);
    text-align: center;
    justify-content: center;
    align-items: center;
`;
const ProfileInfo = ({
    formData,
    handleInputChange,
    checkNickname,
    handleSave,
    handlePasswordReset,
    editMode,
    setEditMode,
    handleCopyInviteCode,
    handleShareInviteCode,
    profile,
}) => {
    return (
        <>
            {editMode ? (
                <>
                    <ProfileInfoContainer>
                        <ProfileInfoItem>
                            <FontAwesomeIcon icon={faUser} />
                            <NicknameContainer>
                                <Input
                                    type="text"
                                    name="nickname"
                                    value={formData.nickname}
                                    onChange={handleInputChange}
                                    className="with-button"
                                />
                                <CheckButton onClick={checkNickname}>
                                    중복확인
                                </CheckButton>
                            </NicknameContainer>
                        </ProfileInfoItem>
                        <ProfileInfoItem>
                            <FontAwesomeIcon icon={faBirthdayCake} />
                            <Input
                                type="date"
                                name="birthdate"
                                value={
                                    new Date(formData.birthdate)
                                        .toISOString()
                                        .split("T")[0]
                                }
                                onChange={handleInputChange}
                            />
                        </ProfileInfoItem>
                        <ProfileInfoItem>
                            <FontAwesomeIcon icon={faCog} />
                            <Select
                                name="visibility"
                                value={formData.visibility}
                                onChange={handleInputChange}
                            >
                                <option value="private">비공개</option>
                                <option value="public">전체공개</option>
                                <option value="followers">팔로워공개</option>
                            </Select>
                        </ProfileInfoItem>
                        <ProfileInfoItem>
                            <PasswordButton onClick={handlePasswordReset}>
                                <FontAwesomeIcon icon={faKey} /> 비밀번호 변경
                            </PasswordButton>
                        </ProfileInfoItem>
                    </ProfileInfoContainer>
                    <ButtonContainer>
                        <SaveButton onClick={handleSave}>
                            <FontAwesomeIcon icon={faSave} /> 저장
                        </SaveButton>
                        <CancelButton onClick={() => setEditMode(false)}>
                            취소
                        </CancelButton>
                    </ButtonContainer>
                </>
            ) : (
                <ProfileInfoContainer>
                    <ProfileInfoItem>
                        <FontAwesomeIcon icon={faUser} />
                        닉네임: {profile.nickname}
                    </ProfileInfoItem>
                    <ProfileInfoItem>
                        <FontAwesomeIcon icon={faEnvelope} />
                        이메일: {profile.email}
                    </ProfileInfoItem>
                    <ProfileInfoItem>
                        <FontAwesomeIcon icon={faBirthdayCake} />
                        생년월일:{" "}
                        {new Date(profile.birthdate).toLocaleDateString()}
                    </ProfileInfoItem>
                    <ProfileInfoItem>
                        <FontAwesomeIcon icon={faStar} />
                        신앙도: {profile.faith_level}
                    </ProfileInfoItem>
                    <ProfileInfoItem>
                        <FontAwesomeIcon icon={faCode} />
                        경험치: {profile.faith_exp}
                    </ProfileInfoItem>
                    <ProfileInfoItem>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        접속 IP: {profile.latest_ip || "없음"}
                    </ProfileInfoItem>
                    <ProfileInfoItem>
                        <InviteCodeText>
                            내 복음전도 코드: {profile.invite_code}
                        </InviteCodeText>
                        <InviteCodeButton onClick={handleCopyInviteCode}>
                            <FontAwesomeIcon icon={faCopy} /> 복사
                        </InviteCodeButton>
                        <InviteCodeButton onClick={handleShareInviteCode}>
                            <FontAwesomeIcon icon={faShareAlt} /> 공유
                        </InviteCodeButton>
                    </ProfileInfoItem>
                </ProfileInfoContainer>
            )}
            {profile.referral_code && (
                <ProfileInfoContainer>
                    <ProfileInfoItem>
                        <FontAwesomeIcon icon={faUser} />
                        복음자: {profile.referral_code}
                    </ProfileInfoItem>
                </ProfileInfoContainer>
            )}
        </>
    );
};

export default ProfileInfo;
