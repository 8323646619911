import React from 'react';

function Gratitude() {
  return (
    <div>
      <h2>감사 페이지</h2>
    </div>
  );
}

export default Gratitude;