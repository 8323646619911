import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

const NicknameStepContainer = styled.div`
  width: 95%;
  max-width: 400px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px 0 0 4px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};

  &:focus {
    border-color: ${({ theme }) => theme.inputFocusBorder};
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledBackground};
    color: ${({ theme }) => theme.disabledText};
    cursor: not-allowed;
  }
`;

const CheckButton = styled.button`
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SubmitButton = styled.button`
  width: 85%;
  padding: 15px;
  background-color: ${(props) =>
    props.active ? props.theme.buttonBackground : props.theme.toggleBorder};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.buttonHoverBackground
        : props.theme.toggleBorder};
  }
`;

const Message = styled.p`
  font-size: 14px;
  margin-top: 5px;
  color: ${(props) => (props.error ? "red" : "green")};
`;

const NicknameStep = ({ updateSignupData, signupData }) => {
  const [nickname, setNickname] = useState(signupData.nickname || "");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isNicknameChecked, setIsNicknameChecked] = useState(false);
  const Server_IP = process.env.REACT_APP_API_URL;

  const handleNicknameCheck = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${Server_IP}/api/user/check-nickname`,
        {
          nickname,
        }
      );
      if (response.data.available) {
        setMessage("사용 가능한 닉네임입니다. ✅");
        setIsError(false);
        setIsNicknameChecked(true);
      } else {
        setMessage(response.data.error || "사용할 수 없는 닉네임입니다. ⚠️");
        setIsError(true);
      }
    } catch (error) {
      setMessage(`${error.response.data.error || ""}❌`);
      setIsError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNicknameChecked) {
      updateSignupData({ nickname });
    } else {
      setMessage("닉네임 중복 확인을 해주세요. 🚦");
      setIsError(true);
    }
  };

  return (
    <NicknameStepContainer>
      <Title>닉네임을 설정해주세요 🎉</Title>
      <Form onSubmit={handleSubmit}>
        <InputContainer>
          <Input
            type="text"
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value);
              setIsNicknameChecked(false);
              setMessage("");
              setIsError(false);
            }}
            placeholder="닉네임"
            required
          />
          <CheckButton
            onClick={handleNicknameCheck}
            disabled={!nickname || isNicknameChecked}
          >
            중복확인
          </CheckButton>
        </InputContainer>
        {message && <Message error={isError}>{message}</Message>}
        <SubmitButton type="submit" active={isNicknameChecked}>
          다음
        </SubmitButton>
      </Form>
    </NicknameStepContainer>
  );
};

export default NicknameStep;
