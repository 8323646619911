import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

const EmailStepContainer = styled.div`
  width: 95%;
  max-width: 400px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

const Title = styled.h3`
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text};
  font-size: 17px;
`;

const Description = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-size: 13px;
  color: ${({ theme }) => theme.text};
  opacity: 0.8;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 4px 0 0 4px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.inputBackground};
  color: ${({ theme }) => theme.inputText};

  &:focus {
    border-color: ${({ theme }) => theme.inputFocusBorder};
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledBackground};
    color: ${({ theme }) => theme.disabledText};
    cursor: not-allowed;
  }
`;

const CheckButton = styled.button`
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const VerificationInput = styled(Input)`
  border-radius: 4px;
  margin-bottom: 10px;
`;

const SubmitButton = styled.button`
  width: 85%;
  padding: 15px;
  background-color: ${(props) =>
    props.active ? props.theme.buttonBackground : props.theme.toggleBorder};
  color: ${({ theme }) => theme.buttonText};
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: ${(props) =>
      props.active
        ? props.theme.buttonHoverBackground
        : props.theme.toggleBorder};
  }
`;

const Message = styled.p`
  font-size: 14px;
  margin-top: 5px;
  color: ${(props) => (props.error ? "red" : "green")};
`;

const ChangeEmail = styled.p`
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.linkColor};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const EmailStep = ({ updateSignupData, signupData }) => {
  const [email, setEmail] = useState(signupData.email || "");
  const [verificationCode, setVerificationCode] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(
    signupData.isEmailChecked || false
  );
  const [isVerificationSent, setIsVerificationSent] = useState(
    signupData.isVerificationSent || false
  );
  const [isVerified, setIsVerified] = useState(signupData.isVerified || false);
  const Server_IP = process.env.REACT_APP_API_URL;
  const name = signupData.name || "회원";

  useEffect(() => {
    if (signupData.email) {
      setEmail(signupData.email);
      setIsEmailChecked(signupData.isEmailChecked || false);
      setIsVerificationSent(signupData.isVerificationSent || false);
      setIsVerified(signupData.isVerified || false);
    }
  }, [signupData]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailCheck = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setMessage("올바른 이메일 주소를 입력해주세요. 📧");
      setIsError(true);
      return;
    }

    try {
      const response = await axios.post(`${Server_IP}/api/auth/check-email`, {
        email,
      });
      if (response.data.available) {
        setMessage("사용 가능한 이메일입니다. 인증 코드를 발송했습니다. 📮");
        setIsError(false);  
        setIsEmailChecked(true);
        setIsVerificationSent(true);
        // updateSignupData 호출 제거
      } else {
        setMessage(response.data.error || "이미 사용 중인 이메일입니다. ⚠️");
        setIsError(true);
      }
    } catch (error) {
      setMessage("이메일 확인 중 오류가 발생했습니다. ❌");
      setIsError(true);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (!isEmailChecked || !isVerificationSent) {
      setMessage("이메일 중복 확인이 필요합니다. 🚦");
      setIsError(true);
      return;
    }

    try {
      const response = await axios.post(`${Server_IP}/api/auth/verify-email`, {
        email,
        verificationCode,
      });
      if (response.data.verified) {
        setIsVerified(true);
        setMessage("이메일 인증이 완료되었습니다. ✅");
        setIsError(false);
        // updateSignupData 호출 제거
      } else {
        setMessage("인증 코드가 일치하지 않습니다. 🚫");
        setIsError(true);
      }
    } catch (error) {
      setMessage("인증 처리 중 오류가 발생했습니다. ❌");
      setIsError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isVerified) {
      updateSignupData({
        email,
        isEmailChecked: true,
        isVerificationSent: true,
        isVerified: true,
      });
    } else {
      setMessage("이메일 인증을 완료해주세요. 🚦");
      setIsError(true);
    }
  };

  const handleChangeEmail = () => {
    setEmail("");
    setVerificationCode("");
    setMessage("");
    setIsError(false);
    setIsEmailChecked(false);
    setIsVerificationSent(false);
    setIsVerified(false);
  };

  return (
    <EmailStepContainer>
      <Title>{`🧑‍🔧${name}님은 어떤 이메일을 사용하시나요 ?`}</Title>
      <Description>회원님의 소중한 정보를 엄중하게 보관해요! 🔒</Description>
      <Form onSubmit={handleSubmit}>
        <InputContainer>
          <Input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsEmailChecked(false);
              setIsVerificationSent(false);
              setMessage("");
              setIsVerified(false);
            }}
            placeholder="example@email.com"
            required
            disabled={isVerified}
          />
          <CheckButton
            onClick={handleEmailCheck}
            disabled={!email || isEmailChecked || isVerified}
          >
            중복확인
          </CheckButton>
        </InputContainer>
        {isVerificationSent && !isVerified && (
          <>
            <VerificationInput
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="인증 코드 입력"
              required
            />
            <CheckButton
              onClick={handleVerifyCode}
              disabled={!verificationCode}
            >
              인증확인
            </CheckButton>
          </>
        )}
        {message && <Message error={isError}>{message}</Message>}
        <SubmitButton type="submit" disabled={!isVerified} active={isVerified}>
          다음
        </SubmitButton>
      </Form>
      {isVerified && (
        <ChangeEmail onClick={handleChangeEmail}>
          다른 이메일을 사용할까요?
        </ChangeEmail>
      )}
    </EmailStepContainer>
  );
};

export default EmailStep;
