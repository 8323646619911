import React from 'react';

function Prayer() {
  return (
    <div>
      <h2>기도 페이지</h2>
    </div>
  );
}

export default Prayer;