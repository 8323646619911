import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeft } from "react-feather";

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.headerBackground};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`;


const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.headerText};
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.toggleBorder};
`;

const ProgressBar = styled.div`
  width: ${(props) => (props.currentStep / props.totalSteps) * 100}%;
  height: 100%;
  background-color: ${({ theme }) => theme.headerHoverText};
  transition: width 0.3s ease-in-out;
  box-shadow: 0 5px 15px ${({ theme }) => theme.headerHoverText}; /* 그림자를 아래로만 적용 */
`;

const Button = styled.div`
  padding: 10px 20px;
  background-color: none;
  color: ${({ theme }) => theme.headerText};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;
function SignupHeader({ currentStep, totalSteps, title }) {
  const navigate = useNavigate();

  const [up, setUp] = useState(0);
  const handleBack = () => {
    navigate(-1);
    setUp(up + 1);
  };

  return (
    <HeaderContainer>
      <TopSection>
        <Button onClick={handleBack}>
          <ArrowLeft size={20} style={{ marginRight: "8px" }} />
        </Button>
        <Title>{title}</Title>
        <div style={{ width: "50px" }}></div>
      </TopSection>
      <ProgressBarContainer>
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      </ProgressBarContainer>
    </HeaderContainer>
  );
}

export default SignupHeader;
